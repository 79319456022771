<template>
  <div class="div-input">
    <!-- <div class="display-only" v-if="isDisplay"></div> -->
    <div :class="['input-custom', { error: isValidate }]">
      <label v-if="textFloat" :class="className">
        {{ textFloat }}
        <span v-if="isRequired" class="text-danger mr-1">*</span>
        <b-button
          v-if="isCopyClipboard && value"
          variant="copy-clipboard"
          v-b-tooltip.hover
          :title="tooltipText"
          @click.prevent="copyClipboard"
          @mouseout="outCopyClipboard"
        >
          <font-awesome-icon icon="copy" />
        </b-button>
      </label>
      <div :class="[{ 'input-with-btn-icon': icon }]">
        <input
          :class="['custom-input f-regular']"
          :type="type"
          :placeholder="placeholder"
          :name="name"
          :required="required"
          :value="value"
          :size="size"
          @input="handleInput"
          @change="onDataChange"
          @keypress="onkeypress"
          @keyup="onkeyup"
          ref="input"
          :maxlength="maxlength"
          :disabled="isDisplay"
        />
        <b-button
          v-if="icon"
          variant="outline-secondary"
          class="btn-icon"
          @click="handleBtnSubmit"
        >
          <font-awesome-icon :icon="icon" />
        </b-button>
      </div>
    </div>
    <span v-if="detail" class="text-desc">{{ detail }}</span>
    <div v-if="v && v.$error">
      <span class="text-error" v-if="v.required == false">
        กรุณากรอกข้อมูลให้ครบถ้วน
      </span>
      <span class="text-error" v-else-if="v.minLength == false"
        >กรุณากรอกอย่างน้อย {{ v.$params.minLength.min }} อักษร</span
      >
      <span class="text-error" v-else-if="v.validateTotal == false"
        >กรุณาระบุจำนวนเงินให้ถูกต้อง</span
      >
      <span class="text-error" v-else-if="v.email == false"
        >กรุณากรอกอีเมล (เช่น email@example.com)",</span
      >
      <span class="text-error" v-else-if="v.numeric == false"
        >กรุณากรอกตัวเลขเท่านั้น</span
      >
      <span class="text-error" v-else-if="v.integer == false"
        >กรุณากรอกตัวเลขเท่านั้น</span
      >
      <span class="text-error" v-else-if="v.maxLength == false"
        >กรุณากรอกไม่เกิน {{ v.$params.maxLength.max }} อักษร</span
      >
      <span class="text-error" v-else-if="v.decimal == false"
        >กรุณากรอกตัวเลขเท่านั้น</span
      >
      <span class="text-error" v-else-if="v.minValue == false"
        >กรุณากรอกตัวเลขเท่านั้น</span
      >

      <span class="text-error" v-else-if="v.alpha == false"
        >กรอกเฉพาะตัวอักษรเท่านั้น</span
      >
      <span class="text-error d" v-else-if="v.url == false"
        >ลิงก์ URL ไม่ถูกต้อง (ตัวอย่าง: https://www.example.com)
      </span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    textFloat: {
      required: false,
      type: String
    },
    className: {
      required: false,
      type: String
    },
    placeholder: {
      required: true,
      type: String
    },
    type: {
      required: true,
      type: String
    },
    required: {
      required: false,
      type: Boolean
    },
    detail: {
      required: false,
      type: String
    },
    size: {
      required: false,
      type: String
    },
    name: {
      required: false,
      type: String
    },
    value: {
      required: false,
      type: [String, Number]
    },
    isDisplay: {
      required: false,
      type: Boolean
    },
    isRequired: {
      required: false,
      type: Boolean
    },
    isValidate: {
      required: false,
      type: Boolean
    },
    v: {
      required: false,
      type: Object
    },
    maxlength: {
      required: false,
      type: [Number, String]
    },
    icon: {
      required: false,
      type: String
    },
    isCopyClipboard: {
      required: false,
      type: Boolean
    }
  },
  data() {
    return {
      tooltipText: ""
    };
  },
  methods: {
    onDataChange(event) {
      this.$emit("onDataChange", event.target.value);
    },
    handleInput($event) {
      this.$emit("input", $event.target.value);
    },
    onkeypress($event) {
      this.$emit("onkeypress", $event);
    },
    onkeyup($event) {
      this.$emit("onkeyup", $event.target.value);
    },
    handleBtnSubmit() {
      this.$emit("submit", this.value);
    },
    focus() {
      this.$refs.input.focus();
    },
    copyClipboard() {
      navigator.clipboard
        .writeText(this.value)
        .then(() => {
          this.tooltipText = "คัดลอกสำเร็จ";
        })
        .catch(err => {
          console.error("Error in copying text: ", err);
        });
    },
    outCopyClipboard() {
      this.tooltipText = "คัดลอก";
    }
  }
};
</script>

<style lang="scss" scoped>
.div-input {
  margin-bottom: 15px;
  position: relative;
  /* white-space: nowrap; */
}
.input-custom {
  padding: 0px;
}
.input-custom input:disabled {
  background-color: #f5f5f5;
}
.input-custom label {
  color: #575757;
  font-size: 14px;
  margin-bottom: 2px;
}
.input-custom input {
  color: #575757;
  background-color: white;
  border: 1px solid #dbdbdb;
  border-radius: 8px;
  padding: 5px 10px;
}
.input-custom input[size="lg"] {
  height: 45px;
}
.input-custom input:focus {
  border: 1px solid #16274a;
}
.input-custom.error input {
  border-color: red !important;
}
::-webkit-input-placeholder {
  /* Edge */
  color: rgba(22, 39, 74, 0.4);
}
:-ms-input-placeholder {
  /* Internet Explorer */
  color: rgba(22, 39, 74, 0.4);
}
::placeholder {
  color: rgba(22, 39, 74, 0.4);
}
.custom-input {
  display: block;
  border: none;
  width: 100%;
}
.text-desc {
  color: rgba(22, 39, 74, 0.4);
  font-size: 12px;
}
.display-only {
  position: absolute;
  z-index: 5000;
  width: 100%;
  height: 100%;
  border-radius: 10px;
}
.text-error {
  color: #ff0000;
  font-size: 14px;
}
.input-with-btn-icon {
  display: flex;
  .custom-input {
    border-radius: 10px 0px 0px 10px !important;
  }
  .btn-icon {
    border-left: 0 !important;
    border-radius: 0px 10px 10px 0px;
  }
}
.btn-copy-clipboard {
  padding: 0;
  color: #832abf;
  cursor: pointer;
  font-size: 12px;
}

@media (max-width: 767.98px) {
  /* .div-input {
    margin-top: 0;
  } */
  .input-custom label {
    font-size: 15px;
  }
}
</style>
